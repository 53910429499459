import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const SecondPage = props => (
  <Layout siteTitle="Home">
    <h1>Hi from the Resources page</h1>
    <p>Welcome to resources page </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
